@keyframes slideInLeft {
    0% {
        transform: translateX(-12rem);
    }

    100% {
        transform: none;
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(12rem);
    }

    100% {
        transform: none;
    }
}

@keyframes slideInBottom {
    0% {
        transform: translateY(2rem);
    }

    100% {
        transform: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}