.astronauts2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1rem;
    grid-gap: 2rem;

    >.astronaut {
        grid-row: span 17;
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: flex;
        align-items: flex-end;

        &.button {
            height: auto;
        }

        >.inner2 {
            padding: 0 3rem 3rem 3rem;
            transform: translateY(100%);

            &,
            >h3,
            >.desc {
                transition: transform 0.3s ease, opacity 0.3s ease;
            }

            >h3 {
                font-size: 4rem;  
                padding-bottom: 1.5rem;              
                text-transform: uppercase;
                transform: translateY(-100%);
                font-family: SpaceGrotesk-Bold;               

            }

            >.desc {
                font-size: 1.5rem;
                opacity: 0;
                font-family: SpaceGrotesk-Medium;

                >p:not(:last-child) {
                    margin-bottom: 2rem;
                }

                >a:not(:last-child) {
                    margin-bottom: 1rem;
                }

                >.desktop {
                    display: block;
                }

                >.mobile {
                    display: none;
                }

                >.email,
                >.phone {
                    color: #fff;
                    display: block;
                }
            }
        }

        &:hover,
        &:focus-within {
            >.inner2 {
                transform: none;

                >h3 {
                    transform: none;
                }

                >.desc {
                    opacity: 1;
                }
            }
        }

        >img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            z-index: -1;
            width: 100%;
            min-height: 100%;
            opacity: 1;

            &[alt='Irik Henry Mosilily'],
            &[alt='Isaac Yoa Tuaba'],
            &[alt='Samson Mosilily'] {
                height: 45rem;
            }
        }

        &:after {
            content: '';
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0.81;
            background: rgba(4, 21, 54, 0.70);
        }

        @media screen and (min-width: 1040px) {

            &:nth-child(4) {
                grid-row: span 23;
            }

            &:nth-child(6) {
                grid-row: span 16;
            }

            &:nth-child(8) {
                grid-row: span 20;
            }

            &:nth-child(2) {
                grid-column: 2;
            }

            &:nth-child(3) {
                grid-column: 3;
                grid-row: 1 / span 23;
            }

            &:nth-child(8) {
                grid-column: 3;
                grid-row: 40 / span 23;
            }

            &:nth-child(14) {
                grid-row: span 23;
            }
        }
    }

    @media screen and (max-width: 1040px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        >.astronaut {
            grid-row: span 12;
            width: auto;
            min-width: 0;

            >.inner {
                padding: 0 1.5rem 1.5rem 1.5rem;

                >h3 {
                    font-size: 3rem;
                    padding-bottom: 1.5rem;
                }

                >.desc>p:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                grid-row: span 17;
            }
        }
    }

    @media screen and (min-width: 501px) {
        >.astronaut {
            &.button {
                >a {
                    height: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        display: block;

        >.astronaut {
            height: 44rem;
            width: 100vw;
            left: -3rem;

            >.inner2>.desc {

                >.desktop {
                    display: none;
                }

                >.mobile {
                    display: block;
                }

                >.email,
                .phone {
                    text-indent: -9999px;
                    height: 16px;
                    width: 20px;
                    background: url('../icons/mail.svg') no-repeat center/cover;
                    display: inline-flex;
                    margin-bottom: 0;
                    line-height: 3rem;
                }

                >.phone {
                    margin-left: 2rem;
                    width: 16px;
                    background-image: url('../icons/phone.svg');
                    color: red;
                }
            }
        }
    }
}