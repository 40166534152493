$color-red: #EC2D59;
$color-dark-blue: #F2C6C6; 
$color-darker-blue: #D23837;

$max-width: 120rem;
$global-padding: 9.6rem 3rem;

$mobile: 415px;
$small-grid: 700px;
$medium-grid: 1040px;

