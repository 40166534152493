/*Import default settings*/

@import 'index';

nav {
    display: flex;
    justify-content: space-between;
    height: 14rem;
    position: relative;
    opacity: 5;
    z-index: 999;    
    
    @media only screen and (min-width: 1280px) {
        padding: 0 4rem 0 4rem;
    }
    
    @media only screen and (min-width: 1440px) {
        padding: 0 10rem 0 10rem;
    }

    &>* {
        position: relative;
        margin-top: 6rem;
        top: -1rem;
        z-index: 99;
    }

    figure {
        right: -5rem;
        top: -1.556rem;
        z-index: 100;
        right: -3rem;
        
/*
        &:nohover {
      pointer-events: none;
    }


        &:hover {
            background-color: inherit;
        }
*/
    }

    label {
        top: -5rem;
        position: relative;
        flex: none;
        width: 100%;
        left: -3rem;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 50%;
            width: 100%;
            height: .2rem;
            background: inherit;
        }

        &:before {
            transform: translateY(-.5rem);
            z-index: 2;
        }

        &:after {
            transform: translateY(.3rem);
            background: #EC2D59;

        }
    }
}


#navCheckbox {
    opacity: 0;
    position: relative;
    cursor: pointer;
    right: 2.9rem;
    top: -1.556rem;
    bottom: -1rem;
    width: 2.4rem;
    height: 6rem;
    z-index: 3;
    margin: 0 .35rem;

    &:checked~#navToggle {

        cursor: pointer;

        /*Turns Lines in to x when clicked or checked*/
        &:before {
            transform: translateY(-.11rem) rotate(45deg);
            transition: transform .2s all ease-in-out;
        }

        &:after {
            transform: translateY(-.1rem) rotate(-45deg);
            transition: transform .2s all ease-in-out;
        }
    }
}



// Menu styling 
#menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    visibility: hidden;

    &>div {
        opacity: 0.98;
		background-color: #01081E;
        width: 1000vw;
        height: 1000vh;
        //border-radius: 50%;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        transform: scale(0);
        transition: all 0.5s ease;

        &>div {
            text-align: center;
            max-width: 100vw;
            max-height: 85vh;
            opacity: 0;
            transition: opacity 0.8s all ease;
        }
    }
}

#menu__items {
    list-style: none;
    padding: .5rem;
    position: relative;
    -webkit-transition: opacity .2s ease, visibility .2s ease;
    transition: opacity .2s ease, visibility .2s ease;
    z-index: 100;

    li {
        list-style: none;
        color: inherit;
        font-size: 4rem;
        margin: 1.5rem 0;
        padding: 1.25rem;
        pointer-events: auto; 
        font-family: SpaceGrotesk-Bold;      

        a {
            color: inherit;
            position: relative;
            font-weight: 700;
            text-decoration: none;
            transition: color .2s ease;
	         &:hover {
				opacity: 0.7;
				background: #E81D60;
			}
			
        }
    }


}

/* Responsible for Showing menu when clicked */

#navCheckbox:checked~#menu {
    visibility: visible;


    div {
        transform: scale(1);

        div {
            opacity: 1;
            transition: opacity 0.4s ease;
        }
    }

}