//@import '../settings';

//from home 
.button {
    left: calc(100% - 2rem);
    position: relative;
    top: 2rem;
    transform: translateX(-100%);
    /*background: #A1112A;*/

    &:before {
        content: '';
        background: #01081E;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        /*height: 100%;
        width: 100%;*/
        z-index: -1;
    }
}

.button3 {
    left: calc(100% - 2rem);
    position: relative;
    top: 2rem;
    transform: translateX(-100%);
    background: #A1112A;

    &:before {
        content: '';
        background: #EC2D59;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
    }
}


// from butoon.scss

.button4 {
    background: #161D2B !important;
    color: #EC2D59 !important;
}

.button2,
.button4 {
    background: #EC2D59;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 3rem 5rem;
    position: relative;
    display: inline-flex;
    transition: box-shadow 0.2s ease;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    min-height: 8rem;

    &.fullsize {
        display: flex;
        flex-direction: column;
        font-size: 4rem;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 4rem;
        text-align: left;
        width: 100%;

        @media screen and (max-width: 800px) {
            height: auto;
        }

        >svg {
            margin-top: 2rem;
        }
    }

    &.small {
        padding: 1.5rem 2.5rem;
        height: 5rem;
    }

    &.center {
        justify-content: center;

        >span {
            margin: 0;
        }
    }

    &[disabled] {
        background: rgba(#eee, 0.5);
        color: #444;
        pointer-events: none;
    }

    >span {
        margin-right: 2rem;
        display: inline-block;
    }

    >.arrow {
        left: 0;
        position: relative;
        top: 0.2rem;
        min-width: 3.6rem;
        width: 3.6rem;
        transition: margin 0.2s ease;
    }

    &.loading {
        >span {
            opacity: 0.3;
        }

        &:before {
            animation: loader 1.1s infinite linear;
            border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
            border-left: 1.1em solid #196866;
            border-radius: 50%;
            border-right: 1.1em solid rgba(255, 255, 255, 0.2);
            border-top: 1.1em solid rgba(255, 255, 255, 0.2);
            content: '';
            font-size: 3px;
            height: 10em;
            left: 50%;
            margin: -5em 0 0 -5em;
            position: absolute;
            top: 40%;
            width: 10em;
        }
    }

    &:hover {
        opacity: 1;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
    }

    &.transparent {
        background: none;
        color: #EC2D59;

        &:hover {
            box-shadow: none;
        }
    }

    // Transitions
    &.move {

        >span,
        >.arrow {
            position: relative;
            transition: transform 0.2s ease-in-out;
        }

        &:hover {

            >span,
            >.arrow {
                transform: translateY(200px);
            }
        }
    }

    &.slideArrow {
        >.arrow {
            &.first {
                opacity: 0;
                position: absolute;
                top: calc(100% - 7.4rem);
                left: 4rem;
                transform: translateX(-40px);
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
                transition-delay: 0s;
            }

            &.second {
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
                transition-delay: 0.2s;
            }
        }

        &:hover {
            >.arrow {
                &.first {
                    opacity: 1;
                    transform: translateX(0px);
                    transition-delay: 0.15s;
                }

                &.second {
                    opacity: 0;
                    transform: translateX(40px);
                    transition-delay: 0s;
                }
            }
        }
    }

    &.slide {
        &.small:hover {
            >.arrow.first {
                transform: translateX(25px);
            }
        }

        >span {
            transition: transform 0.15s ease-in-out;
            transition-delay: 0s;
        }

        >.arrow {
            &.first {
                opacity: 0;
                top: calc(50% - 0.7rem);
                left: 0;
                transform: translateX(-35px);
                position: absolute;
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
                transition-delay: 0s;
            }

            &.second {
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
            }
        }

        &:hover {
            >.label {
                transition-delay: 0.15s;
                transform: translateX(55px);
            }

            >.arrow.first {
                transition-delay: 0.15s;
                transform: translateX(50px);
                opacity: 1;
            }

            >.arrow.second {
                opacity: 0;
                transform: translateX(40px);
            }
        }
    }

    &.slide2 {
        &.small:hover {
            >.arrow.first {
                transform: translateX(25px);
            }
        }

        >span {
            transition: transform 0.15s ease-in-out;
            transition-delay: 0s;
            font-size: 1rem;
        }

        >.arrow {
            &.first {
                opacity: 0;
                top: calc(50% - 0.7rem);
                left: 0;
                transform: translateX(-35px);
                position: relative;
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
                transition-delay: 0s;
            }

            &.second {
                transition: transform 0.15s ease-in-out, opacity 0.25s ease;
            }
        }

        &:hover {
            >.label {
                transition-delay: 0.15s;
                transform: translateX(55px);
            }

            >.arrow.first {
                transition-delay: 0.15s;
                transform: translateX(50px);
                opacity: 1;
            }

            >.arrow.second {
                opacity: 0;
                transform: translateX(40px);
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;

        >.label {
            transform: translateX(20px);
        }

        >.arrow {

            &.first {
                transform: scale(-1) translateX(-35px);
                left: auto;
                right: 0;
            }

            &.second {
                transform: scale(-1);
            }
        }

        &:hover {

            >.label {
                transform: translateX(-40px);
            }

            >.arrow {

                &.first {
                    transform: scale(-1) translateX(25px) !important;
                }

                &.second {
                    transform: scale(-1) translateX(25px) !important;
                }
            }
        }
    }
}

@keyframes loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.arrowHack {
    position: relative !important;
    top: -4rem !important;
    left: -4rem !important;
}


.notFoundbutton {
    margin: 0 auto !important;

    img {
        width: 20rem;

        @media screen and (min-width: 700px) {
            width: 100%;
        }
    }

    .button2 {
        margin-top: 4rem;
        width: 20rem;


    }
}