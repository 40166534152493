$eclipseTransition: margin 1.8s ease-out;

.space {
    position: relative;
    width: 45rem;
    height: 45rem;

    >.background {
        content: "";
        height: 100%;
        width: 100%;
        background: radial-gradient(100% 100% ellipse at center, #081439, transparent);
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 43%;
        left: 58%;
        transform: translate(-50%) translateY(-50%);
        transition: $eclipseTransition;

        &:after {
            content: "";
            height: 5rem;
            width: 5rem;
            border-radius: 50%;
            background: #01081E;
            box-shadow: 0 0 120px 50px #abafa8;
            position: absolute;
            display: block;
            bottom: 43%;
            left: 54%;
        }
    }

    >.planet {
        content: "";
        height: 100%;
        width: 100%;
        background-image: linear-gradient(-180deg, #01081E 0%, #01081E 73%);
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%) translateY(-50%);
        overflow: hidden;
        margin-top: 7rem;

        >.shine {
            content: "";
            height: 50rem;
            width: 50rem;
            background: radial-gradient(200px 200px ellipse at center, #182a4d, transparent);
            position: absolute;
            display: block;
            bottom: 55%;
            left: 33%;
            z-index: 99;
            transition: $eclipseTransition;
        }
    }

    // Moon size
    &.extraSmall {
        transform: scale(0.4);
    }

    &.small {
        transform: scale(0.75);
    }

    &.medium {
        transform: scale(1.56);
    }

    &.large {
        transform: scale(2.1);
    }

    &.big {
        transform: scale(2.7);
    }

    // Moon colors
    &.blue {
        >.planet>.shine {
            background: radial-gradient(200px 200px ellipse at center, #182a4d, transparent);
        }

        >.background {
            background: radial-gradient(50% 50% ellipse at center, #081439, transparent);

            &:after {
                background: #919696;
                box-shadow: 0 0 120px 50px #abafa8;
            }
        }
    }

    &.red {
        >.planet>.shine {
            background: radial-gradient(200px 200px ellipse at center, #4a083e, transparent);
        }

        >.background {
            background: radial-gradient(50% 50% ellipse at center, #081439, transparent);

            &:after {
                background: #ec193e;
                box-shadow: 0 0 120px 50px #ec193e;
            }
        }
    }

    // Light position
    &.middleRight {
        >.planet {
            background-image: linear-gradient(-180deg, #01081E 0%, #01081E 73%);

            >.shine {
                bottom: 20%;
                right: auto;
                top: auto;
                left: 58%;
            }
        }

        >.background {
            top: 50%;
            left: 72%;

            &:after {
                bottom: auto;
                right: auto;
                top: 50%;
                left: 50%;
            }
        }
    }

    &.bottomRight {
        >.planet {
            background-image: linear-gradient(-180deg, #01081E 0%, #01081E 73%);

            >.shine {
                bottom: auto;
                right: auto;
                top: 50%;
                left: 35%;
            }
        }

        >.background {
            top: 85%;
            left: 60%;

            &:after {
                bottom: auto;
                right: auto;
                top: 50%;
                left: 50%;
            }
        }
    }

    &.bottomLeft {
        >.planet {
            background-image: linear-gradient(-180deg, #01081E 0%, #01081E 73%);

            >.shine {
                bottom: auto;
                left: auto;
                top: 35%;
                right: 45%;
            }
        }

        >.background {
            top: 85%;
            left: 35%;

            &:after {
                bottom: auto;
                right: auto;
                top: 50%;
                left: 35%;
            }
        }
    }

    &.topLeft {
        >.planet {
            >.shine {
                top: auto;
                left: auto;
                bottom: 50%;
                right: 35%;
            }
        }

        >.background {
            top: 50%;
            left: 35%;

            &:after {
                right: auto;
                top: auto;
                bottom: 47%;
                left: 47%;
            }
        }
    }

    &.topMiddle {
        >.planet {
            >.shine {
                top: auto;
                left: auto;
                bottom: 65%;
                right: 0;
            }
        }

        >.background {
            top: 42%;
            left: 45%;

            &:after {
                right: auto;
                top: auto;
                bottom: 47%;
                left: 47%;
            }
        }
    }

    @keyframes eclipse {
        0% {
            transform: translate(-50%) translateY(-50%) scale(1);
        }

        50% {
            transform: translate(-50%) translateY(-50%) scale(1.09);
        }

        100% {
            transform: translate(-50%) translateY(-50%) scale(1);
        }
    }
}