@import '../../styles/settings.scss';

.slideInBlock {
    background: #F2F5F6;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    >.slider {
        margin-left: -15.5rem;
        max-width: 90rem;
        padding: 8.6rem 0;
        position: relative;
        width: 100%;

        >img {
            height: auto;
            position: relative;
            width: 100%;
        }
    }

    >.inner {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        justify-content: space-between;
        margin: auto;
        max-width: $max-width;
        padding: 8.6rem 3rem;
        position: relative;
        width: 100%;

        >img {
            max-width: 100%;
            object-fit: contain;
        }

        >.content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 4rem;

            >h1 {
                font-size: 4rem;
                font-weight: 500;
                white-space: pre-line;
            }

            >p {
                font-size: 2rem;
                line-height: 2.8rem;
                margin-top: 1.6rem;
                max-width: 52rem;
                min-width: 20rem;
                opacity: 0.8;
                white-space: pre-line;
            }
        }
    }

    :global(.tennisWatchShowPath) {
        /* background-image: url('../../images/cases/tennis-watch-path-background.svg');*/
        background-position-y: calc(50% + 150px);
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-size: 101%;
    }

    &.noPadding {
        >.slider {
            padding: 0;
        }
    }

    &.right {
        flex-direction: row-reverse;

        >.slider {
            margin: 0 -15.5rem 0 0;

            >img {
                min-height: 100%;
                object-fit: cover;
                object-position: 0 0;
            }
        }

        >.inner {
            display: flex;
            flex-direction: row-reverse;
            position: relative;
            padding-bottom: 10rem;

            >.content {
                margin-right: 11rem;
                margin-left: 0;
                position: relative;
            }
        }
    }

    &.flexEnd {
        >.inner>img {
            margin-top: auto;
        }
    }

    &.moveUp {

        >.slider {
            padding-top: 0;
        }

        >.inner {
            padding: 0 3rem 8.6rem;
        }
    }

    @media screen and (max-width: $medium-grid) {

        >.inner>img {
            margin: auto;
            max-width: 50%;
        }
    }

    @media screen and (max-width: $small-grid) {
        flex-direction: column;

        &.tennisWatch {
            background-position-y: 40%;
        }

        &.right {
            flex-direction: column !important;
            padding-top: 4rem;

            >.inner>.content {
                margin-right: 0;
            }
        }

        >.slider {
            margin: 0;
            max-width: 100%;
            padding: 8rem 3rem 0;
        }

        >.inner {
            flex-direction: column !important;
            padding: 3rem;

            >img {
                height: auto;
                margin: 5rem auto 3rem;
                max-width: 70%;
            }

            >.content {
                margin: 3rem 0 0;
            }
        }
    }
}