.projects {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 5rem;
    position: relative;

    >.project {
        background: #eee;
        display: inline-flex;
        height: 34rem;
        justify-content: space-between;
        overflow: hidden;
        padding: 4rem 4rem 0;
        position: relative;
        grid-column: span 3;
        width: 100%;

        &.button {
            padding: 0;
        }

        >.details {
            margin-right: 2rem;
            min-width: 25rem;
            position: relative;
            z-index: 2;

            :global(.slideArrow) {
                bottom: 4rem;
                position: absolute;

                >span,
                >.arrow {
                    transition: margin 0.15s ease-in, transform 0.15s ease-in, opacity 0.25s ease;
                }

                >span {
                    position: relative;
                    white-space: nowrap;
                    font-weight: bold;
                }

                >.arrow {
                    width: 3.8rem;
                    top: calc(50% - 0.7rem) !important;

                    &:first-child {
                        position: absolute;
                        opacity: 0;
                        left: -8rem;
                    }

                    &:last-child {
                        transition-delay: 0s;
                        position: absolute;
                        margin-left: 2rem;
                    }
                }
            }

            >h3 {
                font-size: 2rem;
            }

            >.desc {
                font-size: 2rem;
                margin: 2rem 0;
            }

            >.type {
                font-size: 1.6rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }

        >img {
            position: relative;
            z-index: 1;
            height: 100%;

            &.background {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                z-index: 0;

                &.height {
                    height: 100%;
                    min-width: 100%;
                }

                &.width {
                    height: 100%;
                    min-width: 100%;
                }
            }
        }

        &:first-child {
            grid-column: span 6;
            height: 40rem;

            &.hide {
                display: none;
            }
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(14),
        &:nth-child(15),
        &:nth-child(16) {
            grid-column: span 2;
        }
    }

    &.browseCase {
        >.project {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                grid-column: span 2;
                height: 34rem;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        >.project {

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(11),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16),
            & {
                grid-column: span 3;
            }
        }
    }

    @media screen and (max-width: 800px) {
        display: block;
        left: -3rem;
        width: 100vw;

        >.project {

            >.details {
                margin-right: 1rem;
            }
        }
    }

    // special rules for cases
    :global(#nordish-market) {
        @media screen and (max-width: 875px) {
            padding-right: 1rem;

            >div:nth-child(2) {
                min-width: 100% !important;
                padding-right: 0;
            }
        }
    }
}