@import '../settings.scss';

.largeImage {
    position: relative;
    overflow: hidden;

    >.videoBlock {
        height: auto;
        overflow: hidden;
        margin-bottom: -3px;
        max-width: $max-width;
        width: 100%;
        margin: auto;

        >video {
            height: auto;
            max-width: $max-width;
            width: 100%;
            margin: auto;
        }
    }

    >img {
        display: block;
        position: relative;
        max-width: 100%;
        min-height: 50rem;
        object-fit: cover;
        margin: auto;
    }

    &.smallImage {
        padding: 5rem 0;
        background: red;
        text-align: center;

        >img {
            min-height: 20rem;
        }
    }

    >.badges {
        position: absolute;
        top: 4rem;
        left: 4rem;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
        color: #999;

        >.badge {
            background: white;
            border: 0.1rem solid rgba(black, 0.15);
            border-radius: 0.6rem;
            margin-bottom: 1.2rem;
            padding: 0.8rem 1rem;
            display: flex;
            align-items: center;
            box-shadow: 0 0.5rem 2rem -0.5rem rgba(black, 0.1);

            svg {
                width: 3.8rem;
                height: 3.8rem;
                margin-right: 0.5rem;
            }

            .title,
            .date {
                display: block;
                line-height: 1.4;
            }

            .title {
                font-size: 1.1rem;
                font-weight: 700;
            }

            .date {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    // Modifiers
    &.picular {
        @media screen and (max-width: 1200px) {
            >img {
                left: 20%;
            }
        }
    }
}

// CENTER BLOCK 
.centerBlock {
    background: #fff;
    color: #000;
    overflow: hidden;

    >.inner {
        display: block;
        position: relative;
        max-width: 100%;
        object-fit: cover;
        margin: auto;
        padding: 0;
        margin: 0;
        overflow: hidden;

        >img {
            width: 100%;
            margin-bottom: -0.3rem;
            min-height: 30rem;
        }

        >.content {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin: auto;
            width: 100%;
            height: 100%;
            top: 0;

            >img {
                width: 18rem;
                height: auto;
                margin-right: 3rem;
            }

            >.block {
                display: block;

                >h1 {
                    margin-bottom: 2rem;
                    font-size: 4rem;
                    font-weight: 500;
                    white-space: pre-line;
                    text-align: center;
                    max-width: 86rem;
                    padding: 0 3rem 3rem 3rem;
                    margin: auto;

                    &.removePadding {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }

                >p {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    max-width: 74rem;
                    opacity: 0.8;
                    position: relative;
                    white-space: pre-line;
                    z-index: 2;
                    text-align: center;

                    >a {
                        color: $color-red;
                    }
                }

                >a:not(:last-child) {
                    margin-right: 2rem;
                }

                &.center {
                    text-align: center;

                    >p {
                        margin: auto;
                    }
                }
            }
        }

        &.block {
            >.content {
                position: relative;
                padding: $global-padding;
            }
        }

        &.left,
        &.right {
            padding: 4rem 0;

            >.content>.block {
                padding-left: 310px;
                padding-right: 310px;
            }

            @media screen and (max-width: 980px) {
                >.content {
                    position: relative;
                    margin-bottom: 3rem;

                    >.block {
                        padding: 3rem;
                    }
                }
            }

            >img {
                width: 30rem;
                margin-top: 3rem;
            }
        }

        @media screen and (max-width: $small-grid) {
            >.content {
                flex-direction: column;

                >img {
                    width: 10%;
                    margin-right: 0;
                    margin-bottom: 1.5rem;
                }

                >.block {
                    text-align: center;

                    >h1 {
                        margin-bottom: 1rem;
                    }

                    >a>img {
                        width: 14rem;
                    }

                    >a:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    &.antistress {
        >.inner>.content>.block>p:before {
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 20%, rgba(255, 255, 255, 0.8) 80%, rgba(255, 255, 255, 0) 100%);
            content: '';
            height: 16rem;
            left: -6rem;
            position: absolute;
            top: -2.5rem;
            width: calc(100% + 12rem);
            z-index: -1;
        }
    }

    @media screen and (max-width: 1000px) {
        >.inner>.content>.block {

            >h1 {
                font-size: 2.5rem;
            }
        }
    }

    @media screen and (max-width: 700px) {
        >.inner>.content>.block {

            >h1 {
                font-size: 2rem;
            }
        }
    }
}


// TEXT BLOCK.SCSS
.textBlock {
    background: #fff;

    >.inner {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: $max-width;
        padding: $global-padding;
        font-weight: normal;

        >h1 {
            font-size: 4rem;
            font-weight: 500;
            white-space: pre-line;
        }

        >p {
            margin: 2rem 0;
            font-size: 2rem;
            max-width: 56rem;
            line-height: 2.8rem;
            opacity: 0.8;
        }

        >a {
            margin-top: -0.7rem;
            font-size: 1.8rem;
            color: $color-red;
            text-decoration: none;

            &:after {
                content: '';
                display: inline-block;
                position: relative;
                top: 0.6rem;
                margin: 2rem 0.5rem 0 2rem;
                /*background: url('../../images/arrow-red.svg') no-repeat 0 0/100%;*/
                width: 7.7rem;
                height: 2.3rem;
                transition: margin 0.2s ease;
            }

            &:hover::after {
                margin: 2rem 0 0 2.5rem;
            }
        }

        >img {
            margin: 7rem auto 0;
            max-width: 100%;
            width: 100%;
            height: auto;

            &.shadow {
                box-shadow: 0 0 30px 0 rgba(84, 98, 109, 0.1);
            }
        }
    }
}

/// THREE BLOCK.SCSS
.threeBlock {
    background: #fff;
    position: relative;

    >.inner {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        margin: auto;
        max-width: $max-width;

        >.blocks {
            display: flex;
            flex-wrap: wrap;
            padding: $global-padding;
            width: 100%;

            >.block {
                position: relative;
                width: 50%;

                &:nth-child(1) {
                    padding-top: auto;
                }

                &:nth-child(2) {
                    margin-bottom: 11rem;
                    padding-left: 9rem;
                }

                &:nth-child(3) {
                    display: flex;
                    justify-content: center;
                    margin-top: -11rem;
                    text-align: center;
                    width: 100%;
                }

                >p,
                >ul {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    opacity: 0.8;
                }

                >ul {
                    margin: 2rem 0 0 1.5rem;
                }

                >h1 {
                    font-size: 4rem;
                    font-weight: 500;
                }

                >p {
                    margin-top: 1.6rem;
                    max-width: 52rem;
                }

                >.matSeIpad {
                    height: auto;
                    margin-left: -75%;
                    width: 185%;

                    @media screen and (max-width: $small-grid + 50px) {
                        margin-left: 0;
                        width: 80%;
                    }
                }
            }

            @media screen and (max-width: $medium-grid) {

                >.block {

                    &:nth-child(1) {
                        position: relative;
                        top: 10%;
                    }
                }
            }

            @media screen and (max-width: $small-grid + 50px) {
                display: flex;
                flex-direction: column;

                >.block {
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    width: 100%;

                    >p {
                        margin: auto;
                        text-align: center;
                    }

                    >ul {
                        padding-top: 4rem;
                        text-align: left;
                        width: 20rem;
                        margin: auto;
                    }

                    &:nth-child(1) {
                        padding-left: 0;
                        top: 0;
                    }

                    &:nth-child(2) {
                        margin: 2rem 0 0;
                        padding: 0;
                    }

                    &:nth-child(3) {
                        display: flex;
                        justify-content: center;
                        margin-top: 3rem;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }
}

// BOOKMARK.SCSS

.bookmarkBlock {
    background: #F2F5F6;
    overflow: hidden;

    >.inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        max-width: $max-width;
        padding: 8.6rem 3rem;
        font-weight: normal;

        &.right {
            flex-direction: row-reverse;
            //padding-bottom: 17rem
            position: relative;
        }

        >.content {
            padding-right: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
            margin-left: 2rem;

            >.text {
                margin-bottom: 2.5rem;
            }

            >.item {
                margin-top: 2.6rem;
                display: flex;
                align-items: center;

                &.text {
                    margin: 1.6rem 0;
                    opacity: 0.8;
                    white-space: pre-line;
                }

                >img {
                    width: 1.7rem;
                    height: auto;
                    position: relative;
                    margin-top: 0.1rem;
                }

                >span {
                    margin-left: 1.8rem;
                    opacity: 0.8;
                    display: flex;
                    align-items: center;
                }
            }

            >h1 {
                font-size: 4rem;
                font-weight: 500;
                white-space: pre-line;
            }

            >p {
                font-size: 2rem;
                max-width: 52rem;
                margin-top: 1.6rem;
                line-height: 2.8rem;
            }

            @media screen and (max-width: 820px) {
                padding-right: 1rem;
                flex: 1;

                >.item {
                    margin-top: 2rem;

                    &.text {
                        margin: 1rem 0 2.1rem;
                    }
                }

                >p {
                    max-width: 35rem;
                }
            }
        }

        @media screen and (max-width: 720px) {
            flex-direction: column;

            &.right {
                flex-direction: column-reverse;
            }

            >.image {
                margin-top: 5rem;
                width: 100%;
                margin-bottom: -23rem;
                margin-left: 22vw;
            }

            >.content {
                padding-left: 0;
                padding-top: 3rem;
                margin-left: 0;

                >p {
                    font-size: 2rem;
                    max-width: 100%;
                    line-height: 2.1rem;
                    margin-top: 2rem;
                }
            }
        }
    }

    /* Modifiers */
    &.picular>.inner {
        @media screen and (max-width: 720px) {
            >.content {
                margin-top: 4.5rem;
            }
        }
    }

    &.paykartan>.inner>img {
        width: 57%;
        height: 57%;
        position: relative;
        margin-bottom: -38rem;
        margin-right: -10rem;
        padding-bottom: 12rem;

        @media screen and (max-width: 720px) {
            width: 56%;
            padding-top: 5rem;
            padding-bottom: 25rem;
        }
    }

    &.matSe>.inner>img {
        width: 64%;
        height: 64%;
        position: relative;
        margin-bottom: -35rem;
        margin-right: -11rem;

        @media screen and (max-width: 720px) {
            width: 80%;
            margin: 0 auto;
            margin-bottom: -40%;
        }
    }
}