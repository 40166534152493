:root {
    --default-color: #01081E;
}

/*Declaring the font families*/

@font-face {
    font-family: SpaceGrotesk-Bold;
    src: url('./fonts/webfont/SpaceGrotesk-Bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: SpaceGrotesk-SemiBold;
    src: url('./fonts/webfont/SpaceGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;

}

@font-face {
    font-family: SpaceGrotesk-Medium;
    src: url('./fonts/webfont/SpaceGrotesk-Medium.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: SpaceGrotesk-Regular;
    src: url(./fonts/webfont/SpaceGrotesk-Regular.woff) format('woff');
    font-weight: 400;
}

@font-face {
    font-family: SpaceGrotesk-Light;
    src: url('./fonts/webfont/SpaceGrotesk-Light.woff') format('woff');
    font-weight: 300;
}


/*Universal reset*/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


body,
.app {
    height: 100%;
    font-family: inherit;
    overflow-x: hidden;

}

html {
    height: 100%;
    color: #fff;
    font-size: 62.5%;
    background: var(--default-color);

}

html,
button {
    font-family: SpaceGrotesk-Regular;
}

a {
    color: #EC2D59;
    text-decoration: none;
    transition: opacity 0.1s ease;
    font-weight: 500;
    font-family: SpaceGrotesk-Medium;

    &:hover {
        opacity: 0.7;
        margin: 0rem -0.1rem;
    }
}

a:active {
    color: #EC2D59;
}

h1 {
    font-size: 4rem;
    letter-spacing: -0.025em;
}

h3 {
    font-size: 3rem;
    letter-spacing: -0.0145em;
}

p {
    font-size: 1.8rem;
    letter-spacing: -0.006em;
}

.app {
    display: block;
    position: relative;
    min-height: 100%;
    width: 100%;
    font-size: 1.6rem;

}