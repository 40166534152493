@import '../../styles/settings.scss';

.heroHeader {
    width: 100%;
    max-width: $max-width;
    padding: 5rem 3rem 10rem;
    margin: auto;

    >h1 {
        color: $color-red;
        font-size: 5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    >h2 {
        font-size: 4rem;
        font-weight: 500;
    }

    @media screen and (max-width: $small-grid) {

        >h1 {
            font-size: 4rem;
        }

        >h2 {
            font-size: 3rem;
        }
    }
}