@import '../../styles/settings.scss';

.projectsBlock {
    background: #fff;

    >.inner {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: $max-width;
        padding: $global-padding;
        font-weight: normal;

        @media screen and (max-width: 600px) {
            padding: 0;
        }

        >.block {
            display: inline-flex;
            justify-content: space-between;
            position: relative;

            @media screen and (max-width: 700px) {
                display: flex;
                flex-direction: column;
            }

            >h2 {
                font-size: 4rem;
                font-weight: 500;
                margin-bottom: 3.5rem;
            }

            >.button {
                position: absolute;
                right: -5rem;
                margin-top: -1rem;
                width: 30rem;
            }

            @media screen and (max-width: 600px) {
                >.button {
                    position: relative;
                    right: auto;
                    // left: -5rem;
                    margin-top: 0;
                }
            }
        }



        >.project {

            >.gridBlock {
                padding-left: 0;
                padding-right: 0;

                >.grid {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}