@import '../styles/settings.scss';
@import '../styles/animations.scss';

.view {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 14rem;

    >.inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        max-width: $max-width;
        padding: 5rem 3rem 0rem;
        width: 100%;

        >.welcome {
            align-items: center;
            display: flex;
            height: 100vh;
            margin-top: -19rem;
            min-height: 70rem;
            position: relative;

            >h1 {
                font-weight: bold;
                margin-left: 10rem;
                max-width: 84rem;
                position: relative;
                z-index: 1;
                opacity: 0;
                animation: slideInLeft 0.3s, fadeIn 0.3s forwards;
                font-family: SpaceGrotesk-Bold;

                >span {
                    color: $color-red;
                }
            }

            >.moon {
                left: 13rem;
                margin-top: -20rem;
                position: absolute;
            }
        }

        >.work {
            position: relative;

            >.text {
                margin-left: 10rem;

                >h1 {
                    color: $color-red;
                    font-family: SpaceGrotesk-Bold;
                    font-size: 4rem;
                }

                >p {
                    font-size: 1.8rem;
                    letter-spacing: 0.045rem;
                    line-height: 2.5rem;
                    margin-top: 2rem;
                    max-width: 37.7rem;
                }
            }

            >button {
                left: calc(100% - 10rem);
                position: relative;
                top: 2rem;
                transform: translateX(-100%);
            }

            >.projects {
                display: grid;
                grid-gap: 2rem;
                grid-template-columns: auto;
                margin-top: 5rem;

                >.project {
                    background: #eee;
                    height: 34rem;

                    &:first-child {
                        grid-column: span 2;
                        height: 40rem;
                    }
                }
            }
        }

        >.partners {
            margin-top: 24rem;
            position: relative;

            >.smallMoon {
                bottom: 89%;
                left: -8rem;
                position: absolute;
                z-index: -2;
            }

            >.moon {
                left: 15rem;
                margin-top: -10rem;
                position: absolute;
                z-index: -1;
            }

            >.text {
                margin-left: 10rem;

                >h1 {
                    color: $color-red;
                    font-family: SpaceGrotesk-Bold;
                }

                >p {
                    font-size: 1.8rem;
                    letter-spacing: 0.045rem;
                    line-height: 2.5rem;
                    margin-top: 2rem;
                    max-width: 37.7rem;
                }
            }

            >.content {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                left: -2rem;
                margin-top: 5rem;
                position: relative;
                width: calc(100% + 4rem);

                >.partner {
                    display: flex;
                    justify-content: center;
                    margin: 5.3rem;

                    >img {
                        max-height: 10rem;
                        width: 19.3rem;
                    }
                }
            }
        }

        @media screen and (max-width: 1200px) {
            >.partners>.content>.partner>img {
                width: 15rem;
            }
        }

        @media screen and (max-width: 1000px) {
            >.welcome>h1 {
                margin: auto;
            }
        }

        @media screen and (max-width: 950px) {
            >.partners>.content>.partner>img {
                width: 16.5rem;
            }
        }

        @media screen and (max-width: 800px) {

            >.welcome>h1,
            >.work>.text,
            >.partners>.text {
                font-size: 3rem;
                margin-left: 0;
            }

            >.work>button {
                left: 100%;
            }

            >.partners {
                margin-top: 10rem;

                >.smallMoon,
                >.moon {
                    display: none;
                }

                >.content>.partner {
                    margin: 2rem;
                }
            }

            @media screen and (max-width: 670px) {
                >.partners>.content>.partner>img {
                    height: auto;
                    max-height: 6rem;
                    width: 12.5rem;

                    @media screen and (max-width: 350px) {
                        margin-top: 5rem;
                        width: 90%;
                    }
                }
            }
        }
    }
}

.Workfooter {
    //     text-align: center;
    padding: 0 5rem 0 5rem;

    @media only screen and (min-width: 1024px) {
        padding: 0 20rem 0 20rem;
    }


}