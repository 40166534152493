.frame {
    stroke: lightgray;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    @media only screen and (min-width: 1024px) {
        stroke: lightgray;
        position: absolute;

        top: 0;
        left: -39rem;
        width: 155%;
        height: 100vh;
        overflow: hidden;
    }

}

/* line 9, _custom/_vector-animations.scss */
.frame * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* line 46, _custom/_vector-animations.scss */
.group1 {
    position: absolute;
    top: -1vh;
    left: -1vw;
}

/* line 50, _custom/_vector-animations.scss */
.group1 svg {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

/* line 56, _custom/_vector-animations.scss */
.group1 path {
    stroke-dasharray: 64 256;
    stroke-dashoffset: 320;
    -webkit-animation: dash-mover1 6s linear infinite;
    animation: dash-mover1 6s linear infinite;
}

/* line 60, _custom/_vector-animations.scss */
.group1 path#Vector1 {
    stroke: #EF2E59;
    opacity: 0.3;
    -webkit-animation-delay: -.35s;
    animation-delay: -.35s;
}

/* line 64, _custom/_vector-animations.scss */
.group1 path#Vector2 {
    stroke: #FEFEF1;
    -webkit-animation-delay: -1.75s;
    animation-delay: -1.75s;
    opacity: 0.1;
}

/* line 68, _custom/_vector-animations.scss */
.group1 path#Vector3 {
    stroke: #FEFEF1;
    opacity: 0.6;
}

/* line 71, _custom/_vector-animations.scss */
.group1 path#Vector4 {
    stroke: #02081E;
}

/* line 74, _custom/_vector-animations.scss */
.group1 path#Vector5 {
    stroke: #EF2E59;
    -webkit-animation-delay: -3.2s;
    animation-delay: -3.2s;
    opacity: 0.6;
}

/* line 78, _custom/_vector-animations.scss */
.group1 path#Vector6 {
    stroke: #FEFEF1;
    opacity: 0.6;
}

@-webkit-keyframes dash-mover1 {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -320;
    }
}

@keyframes dash-mover1 {
    0% {
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dashoffset: -320;
    }
}

@media only screen and (max-width: 560px) {

    /* line 2, _custom/_custom-mobile.scss */
    .cloud {
        left: -90px;
        width: calc(100vw + 90px);
        overflow: hidden;
        height: 100vh;
    }

    /* line 9, _custom/_custom-mobile.scss */
    .group1 {
        top: -32vh;
        left: -62vw;
    }

}