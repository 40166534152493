@import '../styles/settings.scss';
@import '../styles/animations.scss';

.view {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 14rem;

    >.inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0 auto;
        max-width: $max-width;
        padding: 5rem 3rem 0rem;
        width: 100%;

        >.text {
            align-items: center;
            display: flex;
            height: 100vh;
            margin-top: -19rem;
            min-height: 70rem;
            position: relative;

            >h1 {
                font-weight: 700;
                margin-left: 10rem;
                max-width: 84rem;
                position: relative;
                z-index: 1;
                opacity: 0;
                animation: slideInLeft 0.3s, fadeIn 0.3s forwards;
                font-family: SpaceGrotesk-Bold;

                >span {
                    color: $color-red;
                }
            }

            >.moonDesktop {
                left: -10rem;
                position: absolute;
                top: 110%;
                z-index: -1;
            }

            >.moonMobile {
                display: none;
                left: calc(50% - 22.5rem);
                margin-top: -8rem;
                position: absolute;
            }
        }

        >.astronauts {
            position: relative;

            >.text {
                margin: 0 0 6rem 10rem;

                >h1 {
                    color: $color-red;
                    font-family: SpaceGrotesk-Bold;
                }

                >p {
                    font-size: 1.8rem;
                    letter-spacing: 0.045rem;
                    line-height: 2.5rem;
                    margin-top: 2rem;
                    max-width: 37.7rem;
                }
            }
        }

        @media screen and (max-width: 1000px) {
            >.text>h1 {
                margin: auto;
                font-family: SpaceGrotesk-Bold;
            }
        }

        @media screen and (max-width: 800px) {
            >.text {
                min-height: 60rem;
            }

            >.text>h1,
            >.astronauts>.text {
                font-size: 3rem;
                margin-left: 0;
            }

            >.text {

                >.moonDesktop {
                    left: auto;
                    position: absolute;
                    right: calc(100% + 14rem);
                    top: 120%;
                    z-index: -1;
                }

                >.moonMobile {
                    display: block;
                }
            }
        }
    }
}