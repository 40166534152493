@import 'index';
@import '../styles/animations.scss';

$color-red: #FB3658;


.moon {
    left: 34rem;
    margin-top: -8rem;
    position: absolute;

    &.firstView {
        opacity: 0;
        animation: fadeIn 1.6s 0.2s forwards ease-in;
    }

    @media only screen and (min-width: 1024px) {
        top: 30rem;
        left: 100rem;
    }
}

.moonSmall {
    bottom: -13rem;
    left: -23rem;
    position: absolute;
    transition: opacity 0.7s ease;
    z-index: -1;
}

#wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 auto;
    max-width: 120rem;
    padding: 5rem 3rem 0;
    width: 100%;
    animation: fadeIn 1.9s 1.8s forwards ease-in;

    &__welcome {
        align-items: center;
        display: flex;
        height: 100vh;
        min-height: 45rem;
        margin-bottom: 10rem;
        position: relative;

        @media only screen and (min-width: 1024px) {
            margin-top: -19rem;
        }

    }

    &__textH1 {
        margin: auto;
        position: relative;
        font-family: SpaceGrotesk-Bold;
        font-size: 3rem;
                
        h1 {                
             font-family: SpaceGrotesk-Bold;
             font-weight: 700;
			 -webkit-font-smoothing: antialiased;
            }
        

        @media only screen and (min-width: 1024px) {
            font-size: 4rem;
            max-width: 75rem;
            margin-left: 10rem;
            font-family: SpaceGrotesk-Bold;
            font-weight: 700;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
        }

        span {
            color: #EC2D59;
            text-decoration: px solid #EC2D59;
        }
    }

    /* Strategy section*/

    &__information {
        display: flex;
        position: relative;
        pointer-events: none;
        min-height: 70rem;
        font-family: SpaceGrotesk-Bold;
        font-weight: 700;

        &--textHeader {
            max-width: 100%;

            h1 {                
                margin-left: 0;
                margin-bottom: 1rem;
                color: #EC2D59;
                font-family: SpaceGrotesk-Bold;
            }

            p {
                font-size: 4.5rem;
                line-height: 6rem;

                @media only screen and (min-width: 1024px) {
                    max-width: 80rem;
                    font-size: 10rem;
                    line-height: 13rem;
                    font-family: SpaceGrotesk-Bold;
                }
            }
        }
    }

    /* Work Section */
    &__work {
        margin-top: 10rem;
        margin-bottom: 27rem;
        position: relative;



        &--text {
            font-size: 3rem;
            margin-left: 0;

            h1 {
                color: #EC2D59;                
                font-family: SpaceGrotesk-Bold;
            }

            p {
                margin-top: 2rem;
                max-width: 37.7rem;
                letter-spacing: 0.045rem;
                font-size: 1.8rem;
                line-height: 2.5rem;
            }
        }
    }

    &__projects {
        display: block;
        left: -3rem;
        width: 100vw;
        margin-top: 5rem;
        position: relative;

        @media only screen and (min-width: 1024px) {
            display: grid;
            grid-gap: 1.8rem;
            width: calc(100% + 2rem);
            grid-template-columns: repeat(6, 1fr);
            margin: 5rem auto 0;
            position: relative;

            &>a {

                grid-column: span 3;
            }

            &>a:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 4;
                grid-row-start: 1;
                grid-row-end: 3;
            }

            &>a:nth-child(2) {
                grid-column-start: 4;
                grid-column-end: 7;
                grid-row-start: 1;
                grid-row-end: 3;
            }

            &>a:nth-child(n+3) {
                grid-column: span 2;
            }
        }

    }
}


#team,
.team {
    padding-bottom: 25rem;

    h1 {
        color: #EC2D59;        
        font-family: SpaceGrotesk-Bold;
    }

    p {
        font-size: 1.8rem;
        letter-spacing: .045rem;
        line-height: 2.5rem;
        margin-top: 2rem;
        max-width: 37.7rem;
    }

    &__pictures {

        display: block;
        width: 100vw;
        left: -3rem;

        margin-top: 5rem;
        position: relative;
        right: 1rem;

        @media only screen and (min-width: 1024px) {
            display: flex;

            flex-wrap: wrap;
            margin-top: 5rem;
            position: relative;
            right: 1rem;
            width: calc(100% + 2rem);
        }
    }

    &_picture {
        flex-basis: calc(66% - 2rem);
        height: 33.5rem;
        margin: 1rem;
        overflow: hidden;
        position: relative;

        &:nth-child(2),
        &:nth-child(4) {
            flex-basis: calc(34% - 2rem);
        }

        &:nth-child(3) {
            order: 4;
        }

    }

    img {
        min-height: 100%;
        object-fit: cover;
        width: 100%;
    }

    a {
        display: inline-block;
        height: inherit;
        background-color: #EC2D59;
        position: absolute;        
        font-family: SpaceGrotesk-Medium;

        svg {
            display: none;

            @media only screen and (min-width: 1024px) {
                display: inline-block;
            }
        }


        span {
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            font-size: 4rem;
            height: 100%;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;

            text-align: left;
            width: 100%;

            @media only screen and (min-width: 1024px) {
                font-size: 3rem;
                position: relative;
                left: -1rem;
                top: -8rem;
                padding-left: 0;
            }
        }
    }

    &__button {
        background: #EC2D59;
        color: #fff;
        cursor: pointer;
        min-height: 8rem;
        width: 94vw;
        left: -2rem;
        margin: 1rem;
        position: relative;
        padding: 4rem;
        max-width: 34rem;

        @media only screen and (min-width: 1024px) {
            position: relative;
            top: 2rem;
            left: 60rem;
            min-height: 4rem;
            margin: 0;
        }

        &>a {
            display: flex;
            justify-content: space-between;
            font-size: 4rem;
            color: inherit;

            @media only screen and (min-width: 1024px) {
                font-size: 1.6rem;
            }

            span {
                margin-right: 2rem;
                display: inline-block;

                position: relative;
                right: 1rem;
                //width: 20rem;
            }
        }
    }
}

.arrow {
    span {
        font-size: 5rem;
    }
}

// Glitch Animation 
span {
    position: relative;

    &.glitch {
        position: relative;
        mix-blend-mode: lighten;

        &:after {
            animation: glitch 3s infinite linear alternate-reverse;
            background: #01081E;
            clip: rect(0, 900px, 0, 0);
            color: white;
            content: attr(data-text);
            left: 0.2rem;
            letter-spacing: -0.1rem;
            overflow: hidden;
            position: absolute;
            text-shadow: -0.2rem 0 $color-red;
            top: -0.3rem;
        }

        &:before {
            animation: glitch 4s infinite linear alternate-reverse;
            background: #01081E;
            clip: rect(0, 900px, 0, 0);
            color: white;
            content: attr(data-text);
            left: -0.2rem;
            letter-spacing: -0.1rem;
            overflow: hidden;
            position: absolute;
            text-shadow: 0.2rem 0 #71A1FF;
            top: -0.3rem;
        }

        &:nth-child(2) {
            left: -0.3rem;

            &:after,
            &:before {
                letter-spacing: -0.2rem;
            }
        }
    }
}

.mainMoon {

    @media only screen and (min-width: 1024px) {
        width: 75rem;
        height: 75rem;
        position: absolute;
        top: 3rem;
        left: 4rem;
    }
}

.m {
    left: calc(50% - 22.5rem);
    margin-top: -6rem;
    position: absolute;
    transform: scale(1.55);
}

$steps: 20;

@keyframes glitch {
    @for $i from 0 through $steps {
        #{percentage($i*(5/$steps))} {
            clip: rect(random(100)+px, 9999px, random(200)+px, 0);
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.topHack {
    @media only screen and (max-width: 800px) {
        position: relative !important;
        top: -6rem !important;
    }
}

.buttonWidth {
    width: 100% !important;
}

.footer {
    margin-top: 20rem;
}