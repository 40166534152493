@import 'index';


#footer {

    &__section1 {
        @media only screen and (min-width: 1024px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__talk__to__us {
        margin-bottom: 4rem;


        h2 {
            font-size: 3rem;
            margin: 0 0 .5rem 0;
            font-family: SpaceGrotesk-Bold;
        }

        h2:nth-child(2) {
            margin-bottom: 2.5rem;
            font-family: SpaceGrotesk-Bold;
        }

        a {
            color: inherit;
            font-size: 2rem;
            opacity: .6;
            text-decoration: underline;

            &:hover {
                opacity: 0.7;
                background: #EC2D59;
            }
        }
    }

    &__menu {
        display: none;
        font-family: SpaceGrotesk-SemiBold;

        @media only screen and (min-width: 1024px) {

            display: flex;
            flex-direction: column;
            font-size: 1.8rem;
            font-weight: 700;
            letter-spacing: .045rem;
            line-height: 3.75rem;

            &>ul li {
                list-style: none;
                pointer-events: auto;


                a {
                    color: #fff;

                    &:hover {
                        opacity: 0.7;
                        background: #EC193E;
                    }
                }
            }
        }

    }

    &__contact_address {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.8rem;
        font-family: SpaceGrotesk-Light;

        p:nth-child(3) {
            margin-bottom: 3rem;
        }

        &>a {
            color: #fff;

            &:hover {
                opacity: 0.7;
                background: #EC2D59;
            }
        }
    }

    &__enquries {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.5rem;
        margin-top: 3rem;

    }

    hr {
        background: #fff;
        border: 0;
        height: .1rem;
        margin: 5rem 0;
        opacity: .1;
        width: 100%;
    }

    &__socialMedia {

        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 5rem;

        &>ul {
            display: flex;
            justify-content: space-between;
            min-width: 18rem;

            li {
                list-style: none;
            }
        }
    }
}


.buttonScroll {

    border: none;
    box-sizing: border-box;
    bottom: 15rem;
    right: 8rem;
    opacity: 0.35;
    height: 1rem;
    width: 1rem;
    background-color: rgba(0, 2, 8, 0);

    @media only screen and (min-width: 1024px) {
        display: none;
    }


}